<template>
  <section>
    <div class="headbanner" :style="{ backgroundImage: `url(${bannerImage})` }">
      <v-container class="container-custom">
        <v-row>
          <v-col>
            <div class="banner">
              <div>
                <h1>Job Oriented Courses</h1>
                <p>
                  <span>Click on a course to learn more</span>
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container class="container-custom">
      <v-row>
        <v-col>
          <div v-if="isLoading" class="course-grid">
            <div v-for="i in 12" :key="i" class="course-card skeleton-card">
              <v-skeleton-loader type="text" width="100%"></v-skeleton-loader>
            </div>
          </div>
          <div v-else class="course-grid">
            <router-link
              v-for="(course, index) in flattenedCourses"
              :key="index"
              :to="`/course/${course.slug}`"
              class="course-card"
            >
              <div class="course-title">{{ course.trainingTitle }}</div>
            </router-link>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Courses",
  data() {
    return {
      bannerImage: require("@/assets/pattern.jpg"),
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(["allCousesMenu","allMeta"]),
    flattenedCourses() {
      return this.allCousesMenu.flatMap((category) => category.courses);
    },
  },
  methods: {
    ...mapActions(["fetchCourseMenus", "getSingleMetaPage"]),
  },
  metaInfo() {
    return {
      title:
        this.allMeta && this.allMeta.title
          ? this.allMeta.title
          : "Job Oriented Courses",
      meta: [
        {
          name: "description",
          content:
            this.allMeta && this.allMeta.description
              ? this.allMeta.description
              : "Explore our job oriented courses to enhance your career prospects.",
        },
        {
          name: "keywords",
          content:
            this.allMeta && this.allMeta.keywords
              ? this.allMeta.keywords
              : "job oriented courses, career training, professional development",
        },
      ],
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.fetchCourseMenus();
      await this.getSingleMetaPage("allcourses");
    } catch (error) {
      console.error("Error fetching course menus:", error);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.headbanner {
  background-size: contain;
  height: 245px;
  width: 100%;
  background-color: #f6fdff;
  background-position: center center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-repeat: repeat-x;
}

.course-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 50px 0;
}

.course-card {
  border: 1px solid #e8e6e6;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 120px;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

.course-title {
  text-align: center;
  color: rgb(80, 80, 80) !important;
  font-weight: 500;
  font-size: 16px;
}

.banner {
  h1 {
    font-size: 35px;
    font-weight: bold;
  }
  p {
    color: rgb(75, 75, 75);
  }
}

.skeleton-card {
  background-color: #f0f0f0;
}

@media screen and (max-width: 600px) {
  .course-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    padding: 50px 0;
  }
  .headbanner {
    height: auto;
  }
  .banner {
    text-align: center;
  }
}

::v-deep .v-skeleton-loader__text {
  background-color: #e0e0e0 !important;
}
</style>
